import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Box,
  Avatar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const StockDetailsPopup = ({ open, onClose, assetAnalysisData }) => {
  if (!assetAnalysisData) return null; // Prevent rendering if no data

  const handleView = () => {
    window.open(
      `https://assessworth.com/stock/${assetAnalysisData.id}`,
      "_blank"
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
      >
        <DialogTitle sx={{ flexGrow: 1 }}>
          {assetAnalysisData.company_name} ({assetAnalysisData.name})
        </DialogTitle>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent dividers>
        <Grid container spacing={2} alignItems="center">
          {/* Company Icon */}
          <Grid item xs={4} sm={3}>
            <Avatar
              src={assetAnalysisData.icon}
              alt={assetAnalysisData.company_name}
              sx={{ width: 80, height: 80, borderRadius: 2 }}
            />
          </Grid>

          {/* Basic Info */}
          <Grid item xs={8} sm={9}>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ cursor: "pointer", "&:hover": { textDecoration: "none" } }}
              onClick={() => handleView()}
            >
              {assetAnalysisData.company_name} ({assetAnalysisData.name})
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {assetAnalysisData.industry}
            </Typography>
            <Box display="flex" alignItems="center" mt={1}>
              <Avatar
                src={assetAnalysisData.flag}
                alt={assetAnalysisData.country}
                sx={{ width: 20, height: 20, mr: 1 }}
              />
              <Typography variant="body2">
                {assetAnalysisData.country}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Stock Data */}
        <Grid container spacing={2} mt={2}>
          {[
            {
              label: "Return",
              value: parseFloat(assetAnalysisData.geometricReturn).toFixed(3),
            },
            {
              label: "Risk",
              value: parseFloat(
                assetAnalysisData.semiStandardDeviation
              ).toFixed(3),
            },
            {
              label: "Risk-Return Ratio",
              value: parseFloat(assetAnalysisData.coefficientVariation).toFixed(
                3
              ),
            },
            {
              label: "Dividend Yield",
              value: parseFloat(assetAnalysisData.divYield).toFixed(3),
            },
            {
              label: "Beta",
              value: parseFloat(assetAnalysisData.beta).toFixed(3),
            },
            {
              label: "Alpha",
              value: parseFloat(assetAnalysisData.alpha).toFixed(3),
            },
            {
              label: "Market Correlation",
              value: parseFloat(
                assetAnalysisData.assetMarketCorrelation
              ).toFixed(3),
            },
            {
              label: "Idiosyncratic Risk",
              value: parseFloat(assetAnalysisData.idiosyncraticRisk).toFixed(3),
            },
            {
              label: "CAPM Return",
              value: parseFloat(assetAnalysisData.CAPMReturn).toFixed(3),
            },
            {
              label: "Sharpe Ratio",
              value: parseFloat(assetAnalysisData.sharpeRatio).toFixed(3),
            },
            {
              label: "Treynor Ratio",
              value: parseFloat(assetAnalysisData.treynorRatio).toFixed(3),
            },
            {
              label: "Sharpe/Treynor",
              value: parseFloat(assetAnalysisData.sharpeTreynor).toFixed(3),
            },
            {
              label: "Jensen's Alpha",
              value: parseFloat(assetAnalysisData.jensonsAlpha).toFixed(3),
            },
            {
              label: "Adjusted Modigliani-Squared",
              value: parseFloat(assetAnalysisData.adjustedM2).toFixed(3),
            },
            {
              label: "Skewnewss",
              value: parseFloat(assetAnalysisData.skewness).toFixed(3),
            },
            {
              label: "Kurtosis",
              value: parseFloat(assetAnalysisData.kurtosis).toFixed(3),
            },
            {
              label: "Confidence Level",
              value: parseFloat(assetAnalysisData.stockNFConfidence).toFixed(3),
            },
            {
              label: "Utility",
              value: parseFloat(assetAnalysisData.utility).toFixed(3),
            },
          ].map((item, index) => (
            <Grid item xs={4} key={index}>
              <Typography variant="body2" fontWeight="bold">
                {item.label}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default StockDetailsPopup;
