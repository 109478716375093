import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../api/axiosConfig";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import { Box } from "@mui/material";
import { AssetRequestTable, IPSRequestTable } from "./components/RequestTables";
import StockDetailsPopup from "./components/StockDetailsPopup";

function RequestDashboard() {
  const [loading, setLoading] = useState(false); // State for loading
  const [ipsTtable, setIpsTable] = useState([]); // State for loading
  const [assetTable, setAssetTable] = useState([]); // State for loading
  const [openStockAnalysisModal, setOpenStockAnalysisModal] = useState(false);
  const [assetAnalysisData, setAssetAnalysisData] = useState({});

  useEffect(() => {
    fetchIPSData();
    fetchAssetRequestData();
  }, []);

  const fetchIPSData = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/portfolio/get-ips-request-data/"); // Replace with your actual endpoint
      if (response.status === 200) {
        // Check for a successful response
        const data = response.data.data;
        setIpsTable(data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const fetchAssetRequestData = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/portfolio/get-asset-request-data/"); // Replace with your actual endpoint
      if (response.status === 200) {
        // Check for a successful response
        const data = response.data.data;
        setAssetTable(data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const handleIPSResolve = async (selectedId) => {
    try {
      setLoading(true);
      const response = await axios.post("/portfolio/resolve-ips-request/", {
        ips_request_id: selectedId,
      }); // Replace with your actual endpoint
      if (response.status === 200) {
        toast.success(`Resolved the request successfully`);
        fetchIPSData();
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAssetResolve = async (selectedId) => {
    try {
      setLoading(true);
      const response = await axios.post("/portfolio/resolve-asset-request/", {
        asset_request_id: selectedId,
      }); // Replace with your actual endpoint
      if (response.status === 200) {
        toast.success(`Resolved the request successfully`);
        fetchAssetRequestData();
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchSymbolData = async (selectedSymbolId) => {
    if (!selectedSymbolId) {
      toast.warn("Please select valid asset");
      return;
    }
    try {
      const response = await axios.get("/portfolio/get-symbol-analysis/", {
        params: {
          symbol_id: selectedSymbolId,
        },
      }); // Replace with your actual endpoint
      if (response.status === 200) {
        // Check for a successful response
        const data = response.data.data;
        setAssetAnalysisData(data);
        setOpenStockAnalysisModal(true);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
    }
  };

  return (
    <div>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}

      {/* IPS */}
      <Box style={{ marginTop: "20px" }}>
        <div className="row equal-height">
          <div className="col-md-12 mb-4">
            <div className="card h-100">
              <div className="card-title p-3 fw-bold">IPS Requests</div>
              <div className="card-body">
                <IPSRequestTable
                  data={ipsTtable}
                  handleResolve={handleIPSResolve}
                />
              </div>
            </div>
          </div>
        </div>
      </Box>

      {/* ASSET */}
      <Box style={{ marginTop: "20px" }}>
        <div className="row equal-height">
          <div className="col-md-12 mb-4">
            <div className="card h-100">
              <div className="card-title p-3 fw-bold">Asset Requests</div>
              <div className="card-body">
                <AssetRequestTable
                  data={assetTable}
                  handleResolve={handleAssetResolve}
                  handleAnalyzeAsset={fetchSymbolData}
                />
              </div>
            </div>
          </div>
        </div>
      </Box>

      {/* Stock Analysis Component */}
      <StockDetailsPopup
        open={openStockAnalysisModal}
        onClose={() => setOpenStockAnalysisModal(false)}
        assetAnalysisData={assetAnalysisData}
      />

      <ToastContainer />
    </div>
  );
}

export default RequestDashboard;
