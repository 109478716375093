import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Define head cells. Only Name, Industry, Worth, Shares, and Occurrences are sortable.
const headCells = [
  { id: "name", label: "Name", sortable: false },
  { id: "symbolCount", label: "Symbols", sortable: true },
  { id: "annualizedReturn", label: "Annualized Return", sortable: true },
  { id: "divYield", label: "Dividend Yield", sortable: true },
  { id: "utility", label: "Utility", sortable: true },
  { id: "portfolioPerformance", label: "Performance", sortable: true },
  { id: "action", label: "Action", sortable: false },
];

// --- Sorting functions ---
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedArray = array.map((el, index) => [el, index]);
  stabilizedArray.sort((a, b) => {
    const comp = comparator(a[0], b[0]);
    if (comp !== 0) return comp;
    return a[1] - b[1];
  });
  return stabilizedArray.map((el) => el[0]);
}

// --- Main component ---
const PortfolioDataTable = ({
  data,
  currency,
  fetchPortfolioData,
  emailHolder,
}) => {
  // Sorting & Pagination state
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("utility");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Action menu state
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedPortfolioId, setSelectedPortfolioId] = useState(null);
  const [selectedPortfolioName, setSelectedPortfolioName] = useState(null);

  // Handle sorting requests
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Action menu handlers
  const handleMenuOpen = (event, row) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedPortfolioId(row.id);
    setSelectedPortfolioName(row.name);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedPortfolioId(null);
  };

  const handleAnalysis = () => {
    handleMenuClose();
    fetchPortfolioData(selectedPortfolioId);
  };

  const sendEmail = () => {
    handleMenuClose();
    emailHolder(selectedPortfolioId, selectedPortfolioName);
  };

  // Apply sorting and pagination to the data
  const sortedData = stableSort(data, getComparator(order, orderBy));
  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.id === "action" ? "center" : "left"}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.sortable ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    headCell.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {paginatedData.map((row) => (
              <TableRow hover key={row.id}>
                {/* name */}
                <TableCell>{row.name}</TableCell>

                {/* symbolCount */}
                <TableCell>{row.symbolCount}</TableCell>

                {/* annualizedReturn */}
                <TableCell>
                  {parseFloat(row.annualizedReturn).toFixed(2)}%
                </TableCell>

                {/* divYield */}
                <TableCell>{parseFloat(row.divYield).toFixed(2)}%</TableCell>

                {/* utility */}
                <TableCell>{parseFloat(row.utility).toFixed(3)}</TableCell>

                {/* portfolioPerformance */}
                <TableCell>{row.portfolioPerformance}</TableCell>

                {/* Action Menu */}
                <TableCell align="center">
                  <IconButton onClick={(e) => handleMenuOpen(e, row)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {paginatedData.length === 0 && (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No records found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Table Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Action Menu */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleAnalysis}>View Analysis</MenuItem>
        <MenuItem onClick={sendEmail}>Email Owner</MenuItem>
      </Menu>
    </Paper>
  );
};

PortfolioDataTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      symbolCount: PropTypes.number.isRequired,
      annualizedReturn: PropTypes.number.isRequired,
      divYield: PropTypes.number.isRequired,
      utility: PropTypes.number.isRequired,
      portfolioPerformance: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default PortfolioDataTable;
