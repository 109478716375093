import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
//import Loader from "../../components/Loader/Loader"; // Import your loader component
import AssetDashboard from "./AssetDashboard";
import PortfolioDashboard from "./PortfolioDashboard";
import RequestDashboard from "./RequestDashboard";
import IndustryAssetsDashboard from "./IndustryAssetsDashboard";

function Portfolio() {
  //const [loading, setLoading] = useState(false); // State for loading
  const [activeSection, setActiveSection] = useState(null); // Track which section is open

  // Toggle section visibility
  const toggleSection = (section) => {
    setActiveSection((prevSection) =>
      prevSection === section ? null : section
    );
  };

  return (
    <div>
      {/* {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )} */}

      {/* Asset Dashboard Section */}
      <div
        style={{
          marginBottom: "16px",
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            padding: "12px",
            cursor: "pointer",
            backgroundColor: "#f9f9f9",
            borderBottom: activeSection === "asset" ? "1px solid #ccc" : "none",
          }}
          onClick={() => toggleSection("asset")}
        >
          <strong>Asset Dashboard</strong>
        </div>
        {activeSection === "asset" && (
          <div style={{ padding: "12px", backgroundColor: "#ffffff" }}>
            <AssetDashboard />
          </div>
        )}
      </div>

      {/* Portfolio Dashboard Section */}
      <div
        style={{
          marginBottom: "16px",
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            padding: "12px",
            cursor: "pointer",
            backgroundColor: "#f9f9f9",
            borderBottom:
              activeSection === "portfolio" ? "1px solid #ccc" : "none",
          }}
          onClick={() => toggleSection("portfolio")}
        >
          <strong>Portfolio Dashboard</strong>
        </div>
        {activeSection === "portfolio" && (
          <div style={{ padding: "12px", backgroundColor: "#ffffff" }}>
            <PortfolioDashboard />
          </div>
        )}
      </div>

      {/* Requests Section */}
      <div
        style={{
          marginBottom: "16px",
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            padding: "12px",
            cursor: "pointer",
            backgroundColor: "#f9f9f9",
            borderBottom:
              activeSection === "requests" ? "1px solid #ccc" : "none",
          }}
          onClick={() => toggleSection("requests")}
        >
          <strong>Requests</strong>
        </div>
        {activeSection === "requests" && (
          <div style={{ padding: "12px", backgroundColor: "#ffffff" }}>
            <RequestDashboard />
          </div>
        )}
      </div>

      {/* Another Section */}
      <div
        style={{
          marginBottom: "16px",
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            padding: "12px",
            cursor: "pointer",
            backgroundColor: "#f9f9f9",
            borderBottom:
              activeSection === "industry" ? "1px solid #ccc" : "none",
          }}
          onClick={() => toggleSection("industry")}
        >
          <strong>Industry Data</strong>
        </div>
        {activeSection === "industry" && (
          <div style={{ padding: "12px", backgroundColor: "#ffffff" }}>
            <IndustryAssetsDashboard />
          </div>
        )}
      </div>

      {/* Toast Notifications */}
      <ToastContainer />
    </div>
  );
}

export default Portfolio;
