import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../api/axiosConfig";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import { Box, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { PortfolioCards } from "./components/DataCards";
import PortfolioDataTable from "./components/PortfolioDataTable";
import PortfolioDetailsPopup from "./components/PortfolioDetailsPopup";
import StockDetailsPopup from "./components/StockDetailsPopup";
import SendPortfolioEmailPopup from "./components/SendPortfolioEmailPopup";

function PortfolioDashboard() {
  const [loading, setLoading] = useState(false); // State for loading
  const [table, setTable] = useState([]); // State for loading
  const [selectedCurrency, setSelectedCurrency] = useState("1"); // State for loading
  const [selectedCountry, setSelectedCountry] = useState(""); // State for loading
  const [portfolioAnalysisData, setPortfolioAnalysisData] = useState({
    symbols: [],
  });
  const [assetAnalysisData, setAssetAnalysisData] = useState({});
  const [openStockAnalysisModal, setOpenStockAnalysisModal] = useState(false);
  const [openPortfolioAnalysisModal, setOpenPortfolioAnalysisModal] =
    useState(false);
  const [emailPopupOpen, setEmailPopupOpen] = useState(false);
  const [selectedPortfolioId, setselectedPortfolioId] = useState(null);
  const [selectedPortfolioName, setSelectedPortfolioName] = useState(null);
  const [message, setMessage] = useState("");
  const [stats, setStats] = useState({
    allPortfolios: 0,
    activeProp: 0,
    underperformingPortfolios: 0,
  });
  const [currency, setCurrency] = useState({
    id: 1,
    name: "NGN",
    rate: 1650.6,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (country_id, currency_id) => {
    try {
      setLoading(true);
      const response = await axios.get("/portfolio/get-portfolio-data/", {
        params: {
          country_id: country_id,
          currency_id: currency_id,
        },
      }); // Replace with your actual endpoint
      if (response.status === 200) {
        // Check for a successful response
        const data = response.data.data;
        setStats({
          allPortfolios: data.allPortfolios,
          activeProp: data.activeProp,
          underperformingPortfolios: data.underperformingPortfolios,
        });
        setTable(data.generalData);
        setCurrency(data.conversionData);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const onCurrencyChange = (value) => {
    if (value) {
      setSelectedCurrency(value);
      fetchData(selectedCountry, value);
    }
  };

  const onCountryChange = (value) => {
    setSelectedCountry(value);
    fetchData(value, selectedCurrency);
  };

  const fetchPortfolioData = async (selectedPortfolioId) => {
    if (!selectedPortfolioId) {
      toast.warn("Please select valid portfolio");
      return;
    }
    try {
      const response = await axios.get("/portfolio/get-portfolio-analysis/", {
        params: {
          portfolio_id: selectedPortfolioId,
        },
      }); // Replace with your actual endpoint
      if (response.status === 200) {
        // Check for a successful response
        const data = response.data.data;
        setPortfolioAnalysisData(data);
        setOpenPortfolioAnalysisModal(true);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
    }
  };

  const fetchSymbolData = async (selectedSymbolId) => {
    if (!selectedSymbolId) {
      toast.warn("Please select valid asset");
      return;
    }
    try {
      const response = await axios.get("/portfolio/get-symbol-analysis/", {
        params: {
          symbol_id: selectedSymbolId,
        },
      }); // Replace with your actual endpoint
      if (response.status === 200) {
        // Check for a successful response
        const data = response.data.data;
        setAssetAnalysisData(data);
        setOpenStockAnalysisModal(true);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
    }
  };

  const emailHolder = (id, name) => {
    setMessage("");
    setselectedPortfolioId(id);
    setSelectedPortfolioName(name);
    setEmailPopupOpen(true);
  };

  const handleSendMessage = async () => {
    if (!message.trim()) {
      return toast.warn("Enter a message.");
    }
    try {
      setLoading(true);
      const response = await axios.post("/portfolio/send-email-portfolio/", {
        portfolio_id: selectedPortfolioId,
        message: message,
      }); // Replace with your actual endpoint
      if (response.status === 200) {
        toast.success(`Email sent to the portfolio holder`);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setEmailPopupOpen(false);
      setMessage("");
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}

      {/* filter */}
      <Box style={{ marginTop: "20px" }}>
        <div className="card h-100">
          <div className="card-body">
            <FormControl
              size="small"
              variant="outlined"
              style={{
                minWidth: 150,
                margin: "0 8px",
                height: "56px",
                marginTop: "8px",
              }}
            >
              <InputLabel id="currency">Currency</InputLabel>
              <Select
                labelId="currency"
                value={selectedCurrency || ""} // State to manage selected value
                onChange={(e) => onCurrencyChange(e.target.value)} // Handler to update the state
                label="currency"
                style={{ height: "100%" }}
              >
                <MenuItem value="">Currency</MenuItem>
                <MenuItem value="1">Naira</MenuItem>
                <MenuItem value="2">Dollar</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              size="small"
              variant="outlined"
              style={{
                minWidth: 150,
                margin: "0 8px",
                height: "56px",
                marginTop: "8px",
              }}
            >
              <InputLabel id="country">Country</InputLabel>
              <Select
                labelId="country"
                value={selectedCountry || ""} // State to manage selected value
                onChange={(e) => onCountryChange(e.target.value)} // Handler to update the state
                label="country"
                style={{ height: "100%" }}
              >
                <MenuItem value="">Country</MenuItem>
                <MenuItem value="1">Nigeria</MenuItem>
                <MenuItem value="2">USA</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </Box>

      {/* stats */}
      <Box style={{ marginTop: "20px" }}>
        <div className="row equal-height">
          <div className="col-md-12 mb-4">
            <div className="card h-100">
              <div className="card-body">
                <PortfolioCards data={stats} />
              </div>
            </div>
          </div>
        </div>
      </Box>

      {/* stats */}
      <Box style={{ marginTop: "20px" }}>
        <div className="row equal-height">
          <div className="col-md-12 mb-4">
            <div className="card h-100">
              <div className="card-body">
                <PortfolioDataTable
                  data={table}
                  currency={currency}
                  fetchPortfolioData={fetchPortfolioData}
                  emailHolder={emailHolder}
                />
              </div>
            </div>
          </div>
        </div>
      </Box>

      {/* Portfolio Analysis Component */}
      <PortfolioDetailsPopup
        open={openPortfolioAnalysisModal}
        onClose={() => setOpenPortfolioAnalysisModal(false)}
        portfolioAnalysisData={portfolioAnalysisData}
        fetchSymbolData={fetchSymbolData}
      />

      {/* Stock Analysis Component */}
      <StockDetailsPopup
        open={openStockAnalysisModal}
        onClose={() => setOpenStockAnalysisModal(false)}
        assetAnalysisData={assetAnalysisData}
      />

      {/* Email Popup */}
      <SendPortfolioEmailPopup
        open={emailPopupOpen}
        onClose={() => setEmailPopupOpen(false)}
        selectedPortfolioName={selectedPortfolioName}
        handleSendMessage={handleSendMessage}
        message={message}
        setMessage={setMessage}
      />

      <ToastContainer />
    </div>
  );
}

export default PortfolioDashboard;
