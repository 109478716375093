import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faNairaSign } from "@fortawesome/free-solid-svg-icons";

const AssetCards = ({ data, currency }) => {
  const theme = useTheme();
  const icons = { NGN: faNairaSign, USD: faDollarSign };

  const cardStyle = {
    minWidth: 200,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    boxShadow: 3,
    padding: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const labelStyle = {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    fontWeight: 600,
  };

  const valueStyle = {
    color: theme.palette.text.primary,
    fontSize: "1.5rem",
    fontWeight: 700,
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={6} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              <FontAwesomeIcon icon={icons[currency.name]} />
              {new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                currencySign: "accounting",
              }).format(data?.worth || 0)}
            </Typography>
            <Typography sx={labelStyle}>Total Worth</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.symbols || 0)}
            </Typography>
            <Typography sx={labelStyle}>Assets</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data.portfolios)}
            </Typography>
            <Typography sx={labelStyle}>Portfolios</Typography>
          </CardContent>
        </Card>
      </Grid>
      {/* <Grid item xs={6} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data.portfolioSymbols)}
            </Typography>
            <Typography sx={labelStyle}>Portfolio-Symbols</Typography>
          </CardContent>
        </Card>
      </Grid> */}
    </Grid>
  );
};

const PortfolioCards = ({ data }) => {
  const theme = useTheme();

  const cardStyle = {
    minWidth: 200,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    boxShadow: 3,
    padding: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const labelStyle = {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    fontWeight: 600,
  };

  const valueStyle = {
    color: theme.palette.text.primary,
    fontSize: "1.5rem",
    fontWeight: 700,
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={6} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.allPortfolios || 0)}
            </Typography>
            <Typography sx={labelStyle}>Portfolio Count</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data?.activeProp?.toFixed(2) || 0)}%
            </Typography>
            <Typography sx={labelStyle}>Active Portfolios</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={6} md={4}>
        <Card sx={cardStyle}>
          <CardContent>
            <Typography sx={valueStyle}>
              {parseFloat(data.underperformingPortfolios)}
            </Typography>
            <Typography sx={labelStyle}>Underperformers</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export { AssetCards, PortfolioCards };
