import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faNairaSign } from "@fortawesome/free-solid-svg-icons";

const AssetTable = ({ data, handleViewAnalysis }) => {
  const [orderBy, setOrderBy] = useState("utility");
  const [order, setOrder] = useState("desc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const icons = { NGN: faNairaSign, USD: faDollarSign };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId(null);
  };

  const handleVisitPage = () => {
    window.open(`https://assessworth.com/stock/${selectedId}`, "_blank");
    handleClose();
  };

  const sortedData = [...data].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
    if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
    return 0;
  });

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        backgroundColor: "white",
        color: "black",
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {[
                { label: "Icon", key: "icon", sortable: false },
                { label: "Symbol", key: "symbol", sortable: true },
                { label: "Company Name", key: "name", sortable: true },
                { label: "Industry", key: "industry", sortable: true },
                { label: "Price", key: "price", sortable: true },
                { label: "Utility", key: "utility", sortable: true },
              ].map((column) => (
                <TableCell key={column.key} sx={{ color: "black" }}>
                  {column.sortable ? (
                    <TableSortLabel
                      active={orderBy === column.key}
                      direction={orderBy === column.key ? order : "asc"}
                      onClick={() => handleRequestSort(column.key)}
                      sx={{ color: "black" }}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
              <TableCell sx={{ color: "black" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.symbol_id}>
                  <TableCell>
                    <Avatar
                      src={row.icon}
                      alt={row.symbol}
                      sx={{ width: 40, height: 40 }}
                    />
                  </TableCell>
                  <TableCell sx={{ color: "black" }}>{row.symbol}</TableCell>
                  <TableCell sx={{ color: "black" }}>{row.name}</TableCell>
                  <TableCell sx={{ color: "black" }}>{row.industry}</TableCell>
                  <TableCell sx={{ color: "black" }}>
                    <FontAwesomeIcon icon={icons[row.currency]} />
                    {parseFloat(parseFloat(row.price).toFixed(2))}
                  </TableCell>
                  <TableCell sx={{ color: "black" }}>
                    {parseFloat(parseFloat(row.utility).toFixed(2))}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={(event) => handleClick(event, row.symbol_id)}
                      sx={{ color: "black" }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          handleViewAnalysis(selectedId);
                          handleClose();
                        }}
                      >
                        View Analysis
                      </MenuItem>
                      <MenuItem onClick={handleVisitPage}>Visit Page</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
        sx={{ color: "black" }}
      />
    </Paper>
  );
};

export default AssetTable;
