import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";

const IPSRequestTable = ({ data, handleResolve }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("date");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId(null);
  };

  const sortedData = [...data].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
    if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
    return 0;
  });

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        backgroundColor: "white",
        color: "black",
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "white" }}>
              {[
                { label: "Date", key: "date" },
                { label: "User", key: "name" },
                { label: "Details", key: "occupation" },
                { label: "Investment Info", key: "investmentExperience" },
                { label: "Special Interest", key: "specialInterest" },
              ].map((column) => (
                <TableCell
                  key={column.key}
                  sx={{
                    backgroundColor: "white",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.key}
                    direction={orderBy === column.key ? order : "asc"}
                    onClick={() => handleRequestSort(column.key)}
                    sx={{ color: "black" }}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id} sx={{ backgroundColor: "white" }}>
                  <TableCell sx={{ color: "black" }}>
                    {moment(row.date).format("MMM DD, YYYY")}
                  </TableCell>
                  <TableCell sx={{ color: "black" }}>
                    {row.name} ({row.gender}, {row.country})
                  </TableCell>
                  <TableCell sx={{ color: "black" }}>
                    {row.occupation} | {row.reason} | {row.cashFrequency}
                  </TableCell>
                  <TableCell sx={{ color: "black" }}>
                    {row.investmentExperience} ({row.investmentHorizon})
                  </TableCell>
                  <TableCell sx={{ color: "black" }}>
                    {row.specialInterest}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={(event) => handleClick(event, row.id)}
                      sx={{ color: "black" }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          handleResolve(selectedId);
                          handleClose();
                        }}
                      >
                        Resolve
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ color: "black" }}
      />
    </Paper>
  );
};

const AssetRequestTable = ({ data, handleResolve, handleAnalyzeAsset }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("date");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const sortedData = [...data].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
    if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
    return 0;
  });

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        backgroundColor: "white",
        color: "black",
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {[
                { label: "Date", key: "date" },
                { label: "User", key: "name" },
                { label: "Occupation", key: "occupation" },
                { label: "Country", key: "country" },
                { label: "Company", key: "company" },
              ].map((column) => (
                <TableCell key={column.key} sx={{ color: "black" }}>
                  <TableSortLabel
                    active={orderBy === column.key}
                    direction={orderBy === column.key ? order : "asc"}
                    onClick={() => handleRequestSort(column.key)}
                    sx={{ color: "black" }}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell sx={{ color: "black" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell sx={{ color: "black" }}>
                    {moment(row.date).format("MMM DD, YYYY")}
                  </TableCell>
                  <TableCell sx={{ color: "black" }}>
                    {row.name} ({row.gender})
                  </TableCell>
                  <TableCell sx={{ color: "black" }}>
                    {row.occupation}
                  </TableCell>
                  <TableCell sx={{ color: "black" }}>{row.country}</TableCell>
                  <TableCell sx={{ color: "black" }}>
                    {row.company} ({row.symbol})
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={(event) => handleClick(event, row)}
                      sx={{ color: "black" }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          handleAnalyzeAsset(selectedRow.symbol_id);
                          handleClose();
                        }}
                      >
                        Analyze Asset
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleResolve(selectedRow.id);
                          handleClose();
                        }}
                      >
                        Resolve
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ color: "black" }}
      />
    </Paper>
  );
};

AssetRequestTable.propTypes = {
  data: PropTypes.array.isRequired,
};

IPSRequestTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export { IPSRequestTable, AssetRequestTable };
