import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SendPortfolioEmailPopup = ({
  open,
  onClose,
  selectedPortfolioName,
  handleSendMessage,
  message,
  setMessage,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
      >
        <DialogTitle sx={{ flexGrow: 1 }}>
          Send Email to {selectedPortfolioName}'s Owner
        </DialogTitle>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent dividers>
        {/* Message Input Box */}
        <TextField
          label="No need for greetings, go straight to the point..."
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          sx={{
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "black" },
              "&:hover fieldset": { borderColor: "black" },
              "&.Mui-focused fieldset": { borderColor: "black" },
            },
          }}
        />

        {/* Send Button */}
        <Box mt={2} textAlign="right">
          <Button
            variant="contained"
            sx={{
              backgroundColor: "black",
              color: "white",
              "&:hover": { backgroundColor: "gray" },
            }}
            onClick={handleSendMessage}
          >
            Send Message
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SendPortfolioEmailPopup;
