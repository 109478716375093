import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../api/axiosConfig";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import { Box, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import StockDetailsPopup from "./components/StockDetailsPopup";
import AssetTable from "./components/IndustryAssetTable";

function IndustryAssetsDashboard() {
  const [loading, setLoading] = useState(false); // State for loading
  const [selectedCountry, setSelectedCountry] = useState(""); // State for loading
  const [selectedIndustry, setSelectedIndustry] = useState(""); // State for loading
  const [assetAnalysisData, setAssetAnalysisData] = useState({});
  const [openStockAnalysisModal, setOpenStockAnalysisModal] = useState(false);
  const [industryArray, setIndustryArray] = useState([]);
  const [assetArray, setAssetArray] = useState([]);

  useEffect(() => {}, []);

  const onCountryChange = async (value) => {
    setSelectedIndustry("");
    setAssetArray([]);
    setIndustryArray([]);
    setSelectedCountry(value);

    if (!value) return;
    try {
      setLoading(true);
      const response = await axios.get("/portfolio/get-country-industries/", {
        params: {
          country_id: value,
        },
      }); // Replace with your actual endpoint
      if (response.status === 200) {
        setIndustryArray(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const onIndustryChange = async (value) => {
    setSelectedIndustry(value);
    try {
      setLoading(true);
      const response = await axios.get("/portfolio/get-industry-assets/", {
        params: {
          industry_id: value,
          country_id: selectedCountry,
        },
      }); // Replace with your actual endpoint
      if (response.status === 200) {
        setAssetArray(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchSymbolData = async (selectedSymbolId) => {
    if (!selectedSymbolId) {
      toast.warn("Please select valid asset");
      return;
    }
    try {
      const response = await axios.get("/portfolio/get-symbol-analysis/", {
        params: {
          symbol_id: selectedSymbolId,
        },
      }); // Replace with your actual endpoint
      if (response.status === 200) {
        // Check for a successful response
        const data = response.data.data;
        setAssetAnalysisData(data);
        setOpenStockAnalysisModal(true);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
    }
  };

  return (
    <div>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}

      {/* filter */}
      <Box style={{ marginTop: "20px" }}>
        <div className="card h-100">
          <div className="card-body">
            <FormControl
              size="small"
              variant="outlined"
              style={{
                minWidth: 150,
                margin: "0 8px",
                height: "56px",
                marginTop: "8px",
              }}
            >
              <InputLabel id="country">Country</InputLabel>
              <Select
                labelId="country"
                value={selectedCountry || ""} // State to manage selected value
                onChange={(e) => onCountryChange(e.target.value)} // Handler to update the state
                label="country"
                style={{ height: "100%" }}
              >
                <MenuItem value="">Country</MenuItem>
                <MenuItem value="1">Nigeria</MenuItem>
                <MenuItem value="2">USA</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              size="small"
              variant="outlined"
              style={{
                minWidth: 150,
                margin: "0 8px",
                height: "56px",
                marginTop: "8px",
              }}
            >
              <InputLabel id="industry-label">Industry</InputLabel>
              <Select
                labelId="industry-label"
                value={selectedIndustry}
                onChange={(e) => onIndustryChange(e.target.value)} // Handler to update the state
                label="Industry"
                style={{ height: "100%" }}
              >
                {industryArray.map((industry) => (
                  <MenuItem key={industry.id} value={industry.id}>
                    {industry.industry}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </Box>

      {/* stats */}
      <Box style={{ marginTop: "20px" }}>
        <div className="row equal-height">
          <div className="col-md-12 mb-4">
            <div className="card h-100">
              <div className="card-body">
                <AssetTable
                  data={assetArray}
                  handleViewAnalysis={fetchSymbolData}
                />
              </div>
            </div>
          </div>
        </div>
      </Box>

      {/* Stock Analysis Component */}
      <StockDetailsPopup
        open={openStockAnalysisModal}
        onClose={() => setOpenStockAnalysisModal(false)}
        assetAnalysisData={assetAnalysisData}
      />

      <ToastContainer />
    </div>
  );
}

export default IndustryAssetsDashboard;
