import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const PortfolioDetailsPopup = ({
  open,
  onClose,
  portfolioAnalysisData,
  fetchSymbolData,
}) => {
  if (!portfolioAnalysisData) return null; // Prevent rendering if no data

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
      >
        <DialogTitle sx={{ flexGrow: 1 }}>
          {portfolioAnalysisData.name} Portfolio
        </DialogTitle>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent dividers>
        <Grid container spacing={1} mt={2}>
          {portfolioAnalysisData.symbols.map((sym, index) => (
            <Grid item key={index}>
              <Box
                p={1}
                border={1}
                borderRadius={1}
                textAlign="center"
                sx={{
                  cursor: "pointer",
                  "&:hover": { textDecoration: "none" },
                }}
                onClick={() => fetchSymbolData(sym.symbol_id)}
              >
                <Typography fontWeight="bold">{sym.symbol}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {sym.proportion.toFixed(2)}%
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Portfolio Data */}
        <Grid container spacing={2} mt={2}>
          {[
            { label: "Active Age", value: portfolioAnalysisData.activeAge },
            { label: "Symbol Count", value: portfolioAnalysisData.symbolCount },
            {
              label: "Annualized Return",
              value: parseFloat(portfolioAnalysisData.annualizedReturn).toFixed(
                3
              ),
            },
            {
              label: "Historical Expected Return",
              value: parseFloat(
                portfolioAnalysisData.historicalExpectedReturn
              ).toFixed(3),
            },
            {
              label: "Standard Deviation",
              value: parseFloat(
                portfolioAnalysisData.standardDeviation
              ).toFixed(3),
            },
            {
              label: "Market Correlation",
              value: parseFloat(
                portfolioAnalysisData.marketCorrelation
              ).toFixed(3),
            },
            {
              label: "Asset Correlation",
              value: parseFloat(portfolioAnalysisData.assetCorrelation).toFixed(
                3
              ),
            },
            {
              label: "Idiosyncratic Risk",
              value: parseFloat(
                portfolioAnalysisData.idiosyncraticRisk
              ).toFixed(3),
            },
            {
              label: "Beta",
              value: parseFloat(portfolioAnalysisData.beta).toFixed(3),
            },
            {
              label: "Alpha",
              value: parseFloat(portfolioAnalysisData.alpha).toFixed(3),
            },
            {
              label: "Dividend Yield",
              value: parseFloat(portfolioAnalysisData.divYield).toFixed(3),
            },
            {
              label: "Sharpe Ratio",
              value: parseFloat(portfolioAnalysisData.sharpeRatio).toFixed(3),
            },
            {
              label: "Treynor Ratio",
              value: parseFloat(portfolioAnalysisData.treynorRatio).toFixed(3),
            },
            {
              label: "Sharpe/Treynor",
              value: parseFloat(
                portfolioAnalysisData.sharpeTreynorRatio
              ).toFixed(3),
            },
            {
              label: "Jensen's Alpha",
              value: parseFloat(portfolioAnalysisData.jensonsAlpha).toFixed(3),
            },
            {
              label: "Adjusted Modigliani-Squared",
              value: parseFloat(portfolioAnalysisData.adjustedM2).toFixed(3),
            },
            {
              label: "Actual CAPM Return",
              value: parseFloat(portfolioAnalysisData.actualCAPMReturn).toFixed(
                3
              ),
            },
            {
              label: "Weighted CAPM Return",
              value: parseFloat(
                portfolioAnalysisData.weightedCAPMReturn
              ).toFixed(3),
            },
            {
              label: "Utility",
              value: parseFloat(portfolioAnalysisData.utility).toFixed(3),
            },
            {
              label: "Portfolio Performance",
              value: portfolioAnalysisData.portfolioPerformance,
            },
          ].map((item, index) => (
            <Grid item xs={4} key={index}>
              <Typography variant="body2" fontWeight="bold">
                {item.label}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PortfolioDetailsPopup;
